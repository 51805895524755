
//$headstart-white:  #FFFFFF !default;
//$headstart-grey:   #F5F5F5 !default;
//$headstart-blue: #526AFF !default;
//$headstart-header:   #13171F !default;
//
//$dove-grey:     #616161 !default;
//$mine-shaft:    #3E3E3E !default;
//$web-orange:    #FFA300 !default;
//
//$primary:    $headstart-blue;
//$secondary:  $headstart-header;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import "node_modules/bootstrap/scss/bootstrap";
//@import "semantic-ui-css/semantic.min.css";

//@import "../../node_modules/react-quill/dist/quill.snow.css";
//@import 'node_modules/font-awesome/css/font-awesome.min.css';

//body {
//  background-color: $headstart-white;
//  font-family: 'Lato', sans-serif !important;
//}
//
//.container {
//  padding: 0 !important;
//}
//
//.row {
//  margin: 0 !important;
//}
//
//.app-header {
//  background: $headstart-header !important;
//  border-radius: 0 !important;
//  height: 76px;
//}
//
//.ui.steps .step:after {
//  background: transparent !important;
//  border: none !important;
//}
//
//.ui.inverted.menu .header.item {
//  margin: 0 8px 0 8px;
//}
//
//.ui.secondary.inverted.menu a.item {
//  color: #7E7E7E !important;
//}
//
//.ui.steps .step.active {
//  background: #f3f4f5 !important;
//  color: #000000de !important;
//}
//
//.ui.menu .active.item {
//  font-weight: 700;
//}
//
//.ui.secondary.inverted.menu .active.item, .ui.inverted.menu a.item:active, .ui.inverted.menu a.item:hover {
//  background: none !important;
//}
//
//.react-bootstrap-table tbody tr:hover {
//  cursor: pointer;
//  color: #212529;
//  background-color: rgba(0, 0, 0, 0.075) !important;
//}
//
//.cursor-pointer {
//  cursor: pointer;
//}
//
//.react-bootstrap-table th {
//  cursor: pointer;
//}
//.react-bootstrap-table .table-striped tbody tr:nth-of-type(odd) {
//  background-color: #FAFAFB;
//}
//
//.custom-caret-div {
//  display: inline-flex;
//  flex-direction: column;
//  justify-content: center;
//  height: 18px;
//  float: right;
//}
//
//.ui.primary.button:hover {
//  background: #2D4AFD !important;
//}
//
//.hoverEffect:hover{
//  color: #526AFF;
//}
//
//body ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//}